import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';

const NikkeGuidesCrown: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Crown guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_crown.webp"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Crown guide & review</h1>
          <h2>A guide & review for Crown in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>27/04/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Banner" />
        <p>
          <strong>Crown</strong> banner will be available:{' '}
          <strong>4/25 After Maintenance ~ Ends 5/16 04:59 (UTC+9)</strong>
        </p>
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="crown" enablePopover />
        </div>
        <p>All hail the King! Long live the King!</p>
        <p>
          The White Knight has finally graced our dominion with the perfect
          blend of beauty and splendor. Donned in majestic attire, Crown's
          descent fulfilled the promise whispered among all wayfarers. While her
          arrival was highly anticipated, she managed to ignite enthusiasm with
          her radiant kit. A noble defender, one can say, with the ability to
          taunt enemies and shroud herself with invulnerability, is an important
          addition to any teams. She can also greatly increase allies' ATK &
          DEF, reloading speed, and Attack Damage, making her relevant in all
          sorts of situations. All in all, she has successfully raised
          everyone's standard by presenting herself as the worthiest individual
          to inherit the glorious title “a shield and a lance”.
        </p>
        <SectionHeader title="Summary" />
        <h5>Can you give us a brief introduction?</h5>
        <p>
          Crown is one of the best support units available at the moment. With
          breathtaking offensive potential, and the special Taunt +
          Invulnerability, she will be paramount to many teams, from Campaign to
          Bossing. Her reload speed can be handy at times and will soon unravel
          unprecedented synergy opportunities. She will likely stay relevant for
          a long time and is a good investment.{' '}
        </p>
        <h5>Should I pull this unit?</h5>
        <p>
          <strong>Absolutely yes!</strong> One copy is recommended, at the very
          least. She will be useful for veterans & beginners alike, allowing
          them to overcome obstacles in various different ways.
        </p>
        <p>
          Using Mileage on her is recommended, so maybe save your pulls if
          you're just aiming for 1 copy. Pilgrims have 1% rate up banners, so
          ideally you spend pulls on normal 2% rate up, and use golden mileage
          on 1% rate up.
        </p>
        <p>
          We recommend 1 copy for f2p players. However, if you are a spender, we
          recommend pulling for dupes, since her Skill 1 is based on Caster's
          ATK buff. Not only that, Crown can prove to be quite a force of nature
          as a DPS. So she can improve her self DPS with dupes as well! We think
          MLB should do perfectly fine for Crown, with the added bonus of a
          lovely lobby screen. Core 7 for mega simps or super competitive
          players only.
        </p>
        <SectionHeader title="Kit Analysis & Highlights" />
        <p>
          In the history of NIKKE, Crown is most likely the most balanced but at
          the same time broken character out there. She may not be the best DPS,
          but her presence brings too many sweets to ignore.
        </p>
        <h5>The Gatling Lance</h5>
        <p>
          Let's first start with her special targeting system. Crown, unlike
          other combatants, has the ability to sense enemies that have
          "camouflage".{' '}
          <strong>
            These are raptures that cannot be normally targeted by non-manually
            controlled units
          </strong>
          . In other words, in order to hit them, you must manual your unit.
          Thankfully, this is not the case with Crown. She is able to "see
          through" this camouflage and can automatically assault these key
          targets, making it easier to coordinate attacks in a semi-piloted
          environment.
        </p>
        <Alert variant="primary">
          <p>
            You will be seeing these new enemies in Chapter 29 & 30, which are
            added in this update. Please be advised.
          </p>
        </Alert>
        <h5>Frontline & Backline</h5>
        <blockquote>
          <p>
            ■ Activates at the start of Full Burst. Affects all allies who
            previously cast their Burst Skills.
          </p>
          <p>ATK ▲ 64.51% of caster's ATK for 15 sec.</p>
          <p>Reloading Speed ▲ 44.35% for 15 sec.</p>
          <p>
            ■ Affects all allies who did not previously cast their Burst Skills.
          </p>
          <p>DEF ▲ 37.44% of caster's DEF for 15 sec.</p>
          <p>Reloading Speed ▲ 44.35% for 15 sec.</p>
        </blockquote>
        <p>
          Crown divides your team into two imaginary files. The frontline
          consists of units who use Burst Skill in a Burst Chain, whereas the
          backline consists of units who don't. When Full Burst is triggered,
          all units in the frontline gain caster's ATK buff & reload speed.
          Meanwhile, the ones at the back gain DEF & reload speed. The
          percentage value of this ATK buff at max level is equivalent to 43% in
          Attacker's stats. What's stunning is that these buffs last for{' '}
          <strong>15 seconds</strong>, which means there is no downtime at all
          for teams who rotate fast. Buffs will still switch between B3, though.{' '}
        </p>
        <Alert variant="primary">
          <p>
            Do note Red Hood does not receive 2 instances of frontline buffs
            even if she uses Burst Skill in Stage I and III.
          </p>
        </Alert>
        <p>
          Since these buffs are automatically activated at the start of Full
          Burst(s), this allows Crown to be used as an Off-Burst B2 (but why?
          Dont do that.). The ATK buff is particularly useful on units that
          normally do not have too much ATK when using their Burst Skill, such
          as Snow White, PrivM, Emilia, or even SAnis (technically a bursting
          Modernia too). However, it is still nice to have for units whose ATK
          is already oversaturated, like SBS.
        </p>
        <p>
          The reload speed may not be prominent in units whose downtime is
          minimal, but units such as Alice, XLud, and Modernia can benefit from
          it to high degrees, the standout being Alice. It can also be paired
          with other reload buffer(s) to achieve the "Infinite Ammo" phenomenon,
          significantly boosting the damage of certain weapon types and skills.
          For instance, an Alice with Infinite Ammo is deadly because she wants
          to shoot as many times as possible during Burst Skill. Alice + Admi +
          Privaty used to be an all-time favorite combo and is the idea
          underlying this suggestion. The Infinite Ammo strategy is also highly
          effective on MGs because they need to wind up should they stop
          shooting for a long time. It is also awesome on units who reload
          often, like Scarlet.
        </p>
        <p>
          To put it in number, with lv 10 S1 and lv 7 or more of Resilience cube
          (reload cube), MG will never wind up as long as target is still
          available.
        </p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="JSpg2hWcFLQ" />
          </Col>
        </Row>
        <Alert variant="primary">
          <p>
            Units who rely on last bullet hitting also enjoy any reload speed
            buffs because their skills will trigger in shorter intervals.
          </p>
        </Alert>
        <h5>Impenetrable Fortress</h5>
        <blockquote>
          <p>■ Activates after 43 normal attack(s). Affects self.</p>
          <p>
            Relax: HP Potency ▲ 4.06% continuously, stacks up to 20 time(s).
          </p>
          <p>
            ■ Activates when Relax is fully stacked, Affects self after the
            stacks are removed.
          </p>
          <p>Invulnerable for 5 sec.</p>
          <p>Attract: Taunt all enemies for 5 sec.</p>
          <p>Recovers 5.23% of the caster's final Max HP as HP.</p>
          <p>■ Activates when recovery takes effect. Affects all allies.</p>
          <p>Attack Damage ▲ 20.99% for 7 sec.</p>
        </blockquote>
        <p>
          Whenever Crown lands 43 shots (so specific :D), she gains 1 stack of
          Relax, which raises HP Potency. HP Potency is a buff that multiplies
          healing given by owner from any source (For example, Helm or Rapunzel
          with HP Potency will give more heal - be it Lifesteal or Direct Heal).
          After reaching 20 stacks of Relax, she heals herself, taunts all
          enemies for 5s, and gains Invulnerability for the duration. She then
          removes all stacks, and the cycle repeats. Do note that her HP
          Recovery is indeed affected by the self-stacked HP Potency.
        </p>
        <Alert variant="primary">
          <p>
            It takes 43 × 20 = 860 shots to accumulate 20 stacks, and the stack
            clear is triggered immediately upon obtaining the 20th stack. This
            takes approx. ~14.33s of continuous shooting at 60 FPS. Reloading
            will extend the interval, so Max Ammo OL is recommended.
          </p>
        </Alert>
        <p>
          Whenever Crown is healed from any source, she also provides the team
          with Attack Damage for 7s. As the shortest interval between triggers
          is ~14.33s, the base uptime for this buff is a bit less than 50%.
          Hence, bringing a healer with{' '}
          <strong>
            frequent teamwide healing, or targeted healing that can heal Crown
            based on position
          </strong>
          , is required to maximize this buff's uptime. Be careful of deploying
          healers who only heal specific target(s), as they may not be healing
          Crown and thus not triggering the effect.
        </p>
        <h5>Better Safe than Sorry</h5>
        <blockquote>
          <p>■ Affects all allies.</p>
          <p>Attack Damage ▲ 36.24% for 15 sec.</p>
          <p>
            Creates a Shield equal to 10.45% of the caster's final Max HP for 15
            sec.
          </p>
        </blockquote>
        <p>
          When Full Burst is activated, you will always want to have your units
          shoot continuously without going back to cover or doing any
          unnecessary i-frame dancing. With Crown's individual shields that
          rival those of Tia, this has become easier than ever. She is also the
          only 20s B2 unit that provides individual shields. Aria, and Folkwang
          are stuck with 40s, while Centi's shield is single and can be
          unreliable at times. This can also activate Naga's skill 1 Core Damage
          buffs!
        </p>
        <p>
          Crown also massively boosts the team's Attack Damage upon using Burst
          Skill, and as we all know that buff is simply goated. You know what's
          even better? The Attack Damage and Shield last for{' '}
          <strong>15 seconds</strong>, which means with teams who rotate fast
          and get to the next burst within 5 seconds, they can be considered as
          permanent buffs!
        </p>
        <Alert variant="primary">
          <p>
            Keep in mind that Attack Damage buff and ATK buff are seperate
            buffs. Both are multiplicative buffs to the Character's Base ATK.
            Hence, Crown's overall buffs combine to do majestic damage!
          </p>
        </Alert>
        <SectionHeader title="Overall Thoughts" />
        <p>
          Our analysis shows that, in terms of DPS, she can potentially surpass
          Tia & Naga, and therefore it is safe to say that{' '}
          <strong>she is one of the best support units at the moment</strong>.
          Crown can also mitigate even the most lethal damage that would
          one-shot any unit. However, when team healing is needed or quick
          taunts are preferable, perhaps Tia & Naga will have an edge. They can
          also repair cover, whereas Crown can't. However, keep in mind that
          Crown is a solo unit, whereas Tia Naga are a duo. So pairing a healer
          with Crown is not only very safe, but also improves her Skill 2 attack
          damage buff uptime!
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Tier List & Analysis" />
        <h5>
          Campaign (LD) - <strong className="sss">SSS</strong>
        </h5>
        <p>
          Crown can surpass Tia Naga, the current meta combo, when it comes to
          raw damage. However, as mentioned in the previous section, there seems
          to be special use cases for either depending on the stage & featured
          enemies. In low deficit, you should be able to clear stages easily
          with any of them.
        </p>
        <h5>
          Campaign (HD) - <strong className="sss">SSS</strong>
        </h5>
        <p>
          Crown's performance does not seem to degrade with high penalty, and in
          fact, the shielding and invulnerability combined with taunt will be
          extremely useful for blocking attacks that cannot normally be
          i-framed. One concerning weakness that Crown demonstrates is that the
          ATK buff is only applied to units who use Burst Skill, whereas Tia
          Naga will buff the team equally. This becomes more apparent in high
          deficits where ATK buffs play a big role. Note that this
          recommendation is more relevant for extremely high deficit campaign
          (≥36%), and your mileage may vary. Regardless, Crown provides such a
          multitude of buffs that she is still at the very top in high deficit
          campaign as well!
        </p>
        <h5>
          Boss (Solo) - <strong className="sss">SSS</strong>
        </h5>
        <p>
          Again, insane raw offensive buffs. Priceless. Possibly the new team 1
          in raids! She will be seeing unending usage in the top 3 teams in
          Union Raid and Solo Raids! Outside Raids, you always have the
          privilege to test whether Tiga or Crown will help you more. Its safe
          to say that Crown has already raised the bar in terms of high deficit
          Boss clearing, with more and more lowest clears on Campaign Boss
          stages appear to be with Crown, and not Tia Naga!
        </p>
        <h5>
          Boss (Adds) - <strong className="sss">SSS</strong>
        </h5>
        <p>
          It would have been SSS+ if it existed. Now, the reason is because,
          while taunts are generally valuable against bosses with mobs, they can
          quickly spell a unit's death. However, Crown circumvents this by
          giving herself Invulnerability. This means, even if Crown taunts 1000
          enemies at once, she would still take zero damage! The best part? She
          doesn't taunt for a SECOND more than her invulnerability duration
          (which is a big problem with Noah)!
        </p>
        <p>
          Check out this clip on Mother Whale, shared with us by community
          member Raon Miru on Discord:
        </p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="fb7nS7jLsK4" />
          </Col>
        </Row>
        <h5>
          PVP - <strong className="b">B</strong>
        </h5>
        <p>
          In an ideal roster, we will not be using Crown. Machine guns have poor
          burst generation in Arena and can easily feed Jackal & Scarlet. Even
          worse, due to the 30 FPS cap, Crown would take nearly 29 seconds to
          trigger the taunt + invulnerability. At this point, the outcome of the
          battle is most likely already settled. But if you can somehow manage a
          2-2.5RL team with her, Crown won't feed Jackal - Scarlet much due to
          the windup of MG. Her being a Defender also means she can be paired
          with Biscuit for basic P1 tanking. Crown can be assumed as a sidegrade
          or a sometimes weaker option than Poli.
        </p>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          Because of the reload buff, Crown actually has an abnormally high
          uptime, which reduces her dependency on Ammo. We recommend 2x lines of
          Ammo for smoother shooting experience only, but you can always have
          more if you can afford.
        </p>
        <Alert variant="primary">
          <p>
            Remember, Crown is a buffer FIRST. Sure she herself can deliver very
            good damage, which makes ATK and Ele rolls appealing on her, but you
            must remember, Crown is a BUFFER before anything else. Look for that
            Ammo, improve your real DPS, then if you still have spare resources,
            you can start upgrading your Crown.
          </p>
        </Alert>
        <p>
          Ideally, we aim for 2-3 Ammo OL, 4 Elemental Damage, and 4 ATK up.
        </p>
        <h5>Skill Investments</h5>
        <ul>
          <li>
            <strong>Skill 1: 10</strong>
          </li>
          <ul>
            <li>
              Skill 1 is her most important basic skill, not only because it
              gives a huge ATK buff, but also the reload is so lethal on Crown.
              She needs exactly lv 10 S1 and lv 7+ Reload cube to achieve the
              no-wind-up MG, so always prioritize this skill as high as you can.
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <strong>Skill 2: 7-10</strong>
          </li>
          <ul>
            <li>
              Although skill 2 does contribute a lot toward the buffing of
              Crown, it can be compromised a bit. The last 3 levels will cost
              you 15 days of grinding Sim Room 5-C, for just 3% more Attack
              Damage and small taunting/invulnerable. You should finish it, but
              you can put it later if you are short on material and it is
              preventing you from fully upgrading your S1.
            </li>
          </ul>
          <li>
            <strong>Burst: 7-10</strong>
          </li>
          <ul>
            <li>
              The bread and butter of her power. Maximize it as soon as you can.
              Amazing skill.
            </li>
          </ul>
        </ul>
        <p>
          <strong>Suggested Progression Path</strong>
        </p>
        <div className="nikke-skills-tips">
          <div className="nikke-skills-row header">
            <div className="column character">&nbsp;</div>
            <div className="column suggestions pve">PVE Investment</div>
            <div className="column suggestions pvp">PVP Investment</div>
            <div className="column priority">Priority</div>
          </div>
          <div className="nikke-skills-row data">
            <NikkeSkill char="crown" />
          </div>
        </div>
        <br /> <br />
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_3_sm.png"
          alt="Guide"
        />
        <p>
          Since she is an MG, Bastion would always be good, but don't be fooled!
          Crown also grants herself Reload Speed, so Resilience is actually very
          strong on her due to the lack of winding up.{' '}
          <strong>Always use Resilience cube on Crown!</strong>
        </p>
        <StaticImage
          src="../../../images/nikke/generic/crown_rev.jpg"
          alt="Crown"
        />
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="PVE Team Compositions" />
        <h5>Team #1: "All-in on Alice, please!"</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
              <NikkeCharacter mode="icon" slug="d-killer-wife" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Forget healing, Alice is the best DPS in town again (given that you
          are able to spam her ammo)! Infinite ammo on Alice allows her to
          bypass that Ammo OL requirement and focus on dealing deathly damage to
          her opponents! If you use Red Hood as B3, you have a double Pierce
          combo where D:KW will out perform Liter (if both are same
          investments). This team is for campaign, and hence RedHood can be used
          as Solo B1 unit over Liter.
        </p>
        <h5>Team #2: The New God Comp</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
              <NikkeCharacter mode="icon" slug="d-killer-wife" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This is the basic structure for most Crown teams. Naga is the absolute
          BEST choice. Crown + Naga is BETTER than Tia + Naga when it comes to
          overall damage buffs!! The synergy is clear: Crown bursts to provide
          shield to Naga, which activates Naga's Core damage buffs. In return,
          Naga heals Crown and activates her Attack Damage Buff. With crown's
          Skill 1 and Burst buffs in tow, they all combine to provide insane,
          never-seen-before damage buffs to teams! The advantages of being able
          to use Crown over Tia are simply too many!
        </p>
        <p>
          Rapunzel is a more comfortable/casual choice for when you need a safe
          and clutch revive with extra heals. One thing to keep in mind is
          Crown's placement slot. When everyone is full health, the healing
          effects are applied left to right (P1 to P5) . Hence, Crown should be
          placed in Position 1 so that when everyone is full health, she
          receives the heal from Naga/Rapunzel, and activates her skill 2 attack
          damage buff for us!
        </p>
        <h5>Team #3: Tyrant Class Rapture in sight!</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="scarlet-black-shadow"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This team can prove to be really good for Raids and Bossing. The idea
          behind it is that any rapture Boss with a "Targets Highest ATK"
          mechanic will divide its damage dealt to us by 3. Alice, SBS and Crown
          take turns to receive damage. Not to mention, Crown has continuously
          renewing shields, and Alice has the ability to heal herself!
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box sss">
            <h6>SSS</h6>
            <p>Story (High Deficit)</p>
          </div>
          <div className="ratings-box sss">
            <h6>SSS</h6>
            <p>Story (Low Deficit)</p>
          </div>
          <div className="ratings-box sss">
            <h6>SSS</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box sss">
            <h6>SSS</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box b">
            <h6>B</h6>
            <p>PVP</p>
          </div>
        </div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Powerful ATK & reload speed buffs on Full Burst for units
                    who use Burst Skill.
                  </li>
                  <li>
                    Massive Attack Damage buffs that can be easily triggered.
                  </li>
                  <li>
                    Teamwide reload speed buffs are rare and can be combined
                    with each other to create Infinite Ammo phenomenon.
                  </li>
                  <li>Taunt + Invulnerability is unparalleled!</li>
                  <li>
                    Can perform as a DPS because of the insane buffs + Reload
                    Speed!
                  </li>
                  <li>Senses enemies with "camouflage".</li>
                  <li>
                    Can theoretically be used as an Off-Burst B2, but there is
                    no reason to.
                  </li>
                  <li>That gacha skin tho... is &lt;3.</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    FPS dependent. Play 60 FPS or get suboptimal performance on
                    her DPS and Skill 2.
                  </li>
                  <li>
                    ATK buff only applies to specific units (not teamwide).
                  </li>
                  <li>
                    Does not possess team-wide healing, which is the only
                    drawback compared to the two duos.
                  </li>
                  <li>Pilgrim's rate!!!</li>
                  <li>The best in-game skin is gacha.</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesCrown;

export const Head: React.FC = () => (
  <Seo
    title="Crown guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Crown in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
